import React, { useState, useEffect } from 'react';

const Artigos2 = ({ post }) => {
    const [postLoadingState, setPostLoadingState] = useState(true);

    useEffect(() => {
        if (post) {
            setPostLoadingState(false);
        }
    }, [post]);

    if (postLoadingState) {
        return <p>Carregando...</p>;
    }

    if (!post) {
        return <p>Erro ao carregar contactos.</p>;
    }

    return (
        <div>
            <section className="container-fluid bg position-relative">
                <p>{post.post_title}</p>
                <div dangerouslySetInnerHTML={{ __html: post.post_content }} />
            </section>
        </div>
    );
};

export default Artigos2;