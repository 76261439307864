import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Error404 = () => {
    const [postMeta, setPostMeta] = useState(null);
    const [metaLoadingState, setMetaLoadingState] = useState(true);

    useEffect(() => {
        const getMeta = async () =>{
            try {
                const response = await fetch(`https://reactapi.celeuma.pt/wp-json/api/v1/siteinfo`);
                const meta = await response.json();

                setPostMeta(meta);
                setMetaLoadingState(false);
            } catch (error) {
                console.error('Erro a obter meta dados do artigo!', error);
                setMetaLoadingState(false);
            }
        }

        getMeta();
    }, []);
    
    return (
        <>
            {metaLoadingState ? (
                <Helmet>
                    <title>A carregar</title>
                    <meta name="description" content="A carregar" />
                    <link rel="icon" href="favicon.png" />
                    <link rel="apple-touch-icon" href="favicon.png" />
                </Helmet>
            ) : (
                <Helmet>
                    <title>Página não Encontrada - {postMeta.title}</title>
                    <meta name="description" content="Página não Encontrada!" />
                    <link rel="icon" href={postMeta.favicon} />
                    <link rel="apple-touch-icon" href={postMeta.favicon} />
                </Helmet>
            )}

            <section className="container-fluid bg position-relative">
                <p>Erro 404 - Página Não Encontrada!</p>
            </section>
        </>
    );
};

export default Error404;