import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import NotFound from './404';
// import Galeria from 'sjgallery';
import Galeria from './gallery';

const viewportSizes = [
    { viewportSize: 0, itemsPerRow: 1, itemsPerDot: 1, gridLayout: [], galleryHeight: 'var(--midScreen)', nav: true, dots: true, lightbox: true, lightboxNav: true, lightboxThumbnails: true, onlyLightbox: true, openLightboxButtonText: 'Gallery' },
    { viewportSize: 768, itemsPerRow: 2, itemsPerDot: 2, gridLayout: [[1, 2], [1, 2], [2, 1], [2, 1], [1, 2], [1, 2], [2, 1], [2, 1]], galleryHeight: 'var(--midScreen)', nav: true, dots: true, lightbox: true, lightboxNav: true, lightboxThumbnails: true, onlyLightbox: false },
    { viewportSize: 992, itemsPerRow: 4, itemsPerDot: 4, gridLayout: [[1, 2], [2, 1], [1, 2], [2, 1], [2, 1], [2, 1], [1, 2], [1, 2], [2, 1], [2, 1]], galleryHeight: 'var(--midScreen)', nav: true, dots: true, lightbox: true, lightboxNav: true, lightboxThumbnails: true, onlyLightbox: false },
];

const themeColors = {
    lightboxBackground: 'rgba(233, 233, 233, 0.9)',
    captionBackground: 'rgba(233, 233, 233, 0.5)',
    captionColor: 'red',
    navColor: 'blue',
    navBackground: 'rgba(233, 232, 233, 0.5)',
    navColorHover: 'red',
    navBackgroundHover: 'rgba(233, 232, 233, 1)',
    lightboxNavColor: 'red',
    lightboxNavHoverColor: 'green',
    dotColor: 'blue',
    dotActiveColor: 'green',
    inactiveThumbnailsBorder: '2px solid red',
    inactiveThumbnailsFilter: 'sepia(1)',
    activeThumbnailBorder: '2px solid green',
    activeThumbnailfilter: 'sepia(0)',
    openLightboxButtonColor: 'white',
    openLightboxButtonBackground: 'rgba(233, 233, 233, 1)',
};

const items = [
    <Link to="https://google.com" className="div"><div>div1</div></Link>,
    <Link to="https://google.com" className="div"><div>div2</div></Link>,
    <Link to="https://google.com" className="div"><div>div3</div></Link>,
    <Link to="https://google.com" className="div"><div>div4</div></Link>,
    <Link to="https://google.com" className="div"><div>div5</div></Link>,
    <Link to="https://google.com" className="div"><div>div6</div></Link>,
    <Link to="https://google.com" className="div"><div>div7</div></Link>,
    <Link to="https://google.com" className="div"><div>div8</div></Link>,
]

const Artigos = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [postLoadingState, setPostLoadingState] = useState(true);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const getPosts = async () => {
            try {
                const response = await fetch(`https://reactapi.celeuma.pt/wp-json/api/v1/item?slug=${slug}&type=post`);
                if (!response.ok) {
                    setNotFound(true);
                } else {
                    const posts = await response.json();
                    const imagesWithCaptions = posts.meta['galeria'].map((url, index) => {
                        const caption = `Caption for image ${index + 1}`;
                        return [url, caption];
                    });
                    const updatedPost = {
                        ...posts,
                        meta: {
                            ...posts.meta,
                            galeria: imagesWithCaptions
                        }
                    };
                    setPost(updatedPost);
                    setPostLoadingState(false);
                    setNotFound(false);
                }
            } catch (error) {
                console.error('Erro a obter o artigo!', error);
                setPostLoadingState(false);
                setNotFound(true);
            }
        };

        setPostLoadingState(true);
        setNotFound(false);

        getPosts();
    }, [slug]);

    return (
        notFound ? (
            <NotFound />
        ) : (
            postLoadingState ? (
                <>
                    <Helmet>
                        <title>A carregar</title>
                        <meta name="description" content="A carregar" />
                        <link rel="icon" href="favicon.png" />
                        <link rel="apple-touch-icon" href="favicon.png" />
                    </Helmet>
                    <div className="container-fluid bg">
                        <p>A carregar...</p>
                    </div>
                </>
            ) : (
                <>
                    <Helmet>
                        {post.meta_title ? (
                            <title>{post.meta_title}</title>
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_metadesc'] ? (
                            <meta name="description" content={post.meta['_yoast_wpseo_metadesc'][0]} />
                        ) : (
                            ''
                        )}
                        {post.favicon ? (
                            <>
                                <link rel="icon" href={post.favicon} />
                                <link rel="apple-touch-icon" href={post.favicon} />
                            </>
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_opengraph-title'] ? (
                            <meta property="og:title" content={post.meta['_yoast_wpseo_opengraph-title'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_opengraph-description'] ? (
                            <meta property="og:description" content={post.meta['_yoast_wpseo_opengraph-description'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_opengraph-image'] ? (
                            <meta property="og:image" content={post.meta['_yoast_wpseo_opengraph-image'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_twitter-title'] ? (
                            <meta name="twitter:title" content={post.meta['_yoast_wpseo_twitter-title'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_twitter-description'] ? (
                            <meta name="twitter:description" content={post.meta['_yoast_wpseo_twitter-description'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_twitter-image'] ? (
                            <meta name="twitter:image" content={post.meta['_yoast_wpseo_twitter-image'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_focuskw'] ? (
                            <meta name="keywords" content={post.meta['_yoast_wpseo_focuskw'][0]} />
                        ) : (
                            ''
                        )}
                        <meta name="twitter:card" content="summary_large_image" />
                    </Helmet>
                    <section className="container-fluid bg position-relative fadeInSection">
                        <p>{post.post_title}</p>
                        <div dangerouslySetInnerHTML={{ __html: post.post_content }} />
                        {post.meta['galeria'] || items ? (
                            <Galeria 
                                items={post.meta['galeria']} //items devem ser um array de html, caso sejam imagens deve ser um array de arrays [url, caption]
                                //imageGallery={false}
                                // layout="carousel"
                                zIndexElements={['nav', 'header']}
                                // navText={['←', '→']}
                                // lightboxNavText={['←', '→', '×']} //para inserir html basta por o elemento sem aspas
                                //colors={themeColors} 
                                responsive={viewportSizes}
                            />
                        ) : (
                            ''
                        )}
                    </section>
                </>
            )
        )
    );
};

export default Artigos;