import React, { useRef, useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from 'popmotion';
import '../assets/css/gallery.css';

/*Gallery and Lightbox Swipe Variants and Speed*/
const slideVariants = {
    enter: (direction) => ({
        x: direction > 0 ? '100%' : '-100%',
    }),
    center: {
        x: 0,
    },
    exit: (direction) => ({
        x: direction < 0 ? '100%' : '-100%',
    })
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

/*Default Responsive Array for useEffect (so it doesn't update on every render)*/
const defaultResponsive = [
    { viewportSize: 992, itemsPerRow: 3, itemsPerDot: 3, gridLayout: [], galleryHeight: '100svh', nav: true, dots: true, lightbox: true, lightboxNav: true, lightboxThumbnails: true, onlyLightbox: false, openLightboxButtonText: 'Gallery' },
    { viewportSize: 768, itemsPerRow: 2, itemsPerDot: 2, gridLayout: [], galleryHeight: '100svh', nav: true, dots: true, lightbox: true, lightboxNav: true, lightboxThumbnails: true, onlyLightbox: false, openLightboxButtonText: 'Gallery' },
    { viewportSize: 0, itemsPerRow: 1, itemsPerDot: 1, gridLayout: [], galleryHeight: '100svh', nav: true, dots: true, lightbox: true, lightboxNav: true, lightboxThumbnails: true, onlyLightbox: false, openLightboxButtonText: 'Gallery' },
];

/*To sort inputted responsive array by descending viewportsize*/
const sortByViewportSizeDescending = (viewportSizes) => {
    return viewportSizes.slice().sort((a, b) => b.viewportSize - a.viewportSize);
};

/*Gallery Component*/
const Gallery = ({
    items = [],
    imageGallery = true,
    layout = 'grid',
    zIndexElements = [],
    navText=['←', '→'],
    lightboxNavText=['←', '→', '×'],
    colors = {},
    responsive = defaultResponsive,
}) => {
    /*Needed variables*/
    const [[page, direction], setPage] = useState([0, 0]);
    const [[numVisible, itemsPerDot, gridLayout, galleryHeight, nav, dots, lightbox, lightboxNav, lightboxThumbnails, onlyLightbox, openLightboxButtonText], setNumVisible] = useState([3, 1, [], '100svh', true, true, true, true, true, false, 'Gallery']);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [lightboxActive, setLightboxActive] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const thumbnailsRef = useRef(null);
    const thumbnailRefs = useRef([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    /*Handles Viewport Resize for Gallery Items, Dots and Lightbox Thumbnails*/
    useEffect(() => {
        const handleResize = () => {
            const viewportWidth = window.innerWidth;
            let currentNumVisible = [3, 1, [[1, 1]], '100svh', true, true, true, true, true, false];
            const currentScrollPosition = thumbnailsRef.current ? thumbnailsRef.current.scrollLeft : 0; //rever
            let defaultViewportSizeIndex = 0;

            defaultResponsive.some((viewportSize, index) => {
                if (viewportWidth >= viewportSize.viewportSize) {
                    defaultViewportSizeIndex = index;
                    return true;
                }
                return false;
            });

            const sortedResponsive = sortByViewportSizeDescending(responsive);

            sortedResponsive.some((viewportSize) => {
                if (viewportWidth >= viewportSize.viewportSize) {
                    currentNumVisible = [
                        viewportSize.itemsPerRow !== undefined ? viewportSize.itemsPerRow : defaultResponsive[defaultViewportSizeIndex].itemsPerRow,
                        viewportSize.itemsPerDot !== undefined ? viewportSize.itemsPerDot : defaultResponsive[defaultViewportSizeIndex].itemsPerDot,
                        viewportSize.gridLayout !== undefined ? viewportSize.gridLayout : defaultResponsive[defaultViewportSizeIndex].gridLayout,
                        viewportSize.galleryHeight !== undefined ? viewportSize.galleryHeight : defaultResponsive[defaultViewportSizeIndex].galleryHeight,
                        viewportSize.nav !== undefined ? viewportSize.nav : defaultResponsive[defaultViewportSizeIndex].nav,
                        viewportSize.dots !== undefined ? viewportSize.dots : defaultResponsive[defaultViewportSizeIndex].dots,
                        viewportSize.lightbox !== undefined ? viewportSize.lightbox : defaultResponsive[defaultViewportSizeIndex].lightbox,
                        viewportSize.lightboxNav !== undefined ? viewportSize.lightboxNav : defaultResponsive[defaultViewportSizeIndex].lightboxNav,
                        viewportSize.lightboxThumbnails !== undefined ? viewportSize.lightboxThumbnails : defaultResponsive[defaultViewportSizeIndex].lightboxThumbnails,
                        viewportSize.onlyLightbox !== undefined ? viewportSize.onlyLightbox : defaultResponsive[defaultViewportSizeIndex].onlyLightbox,
                        viewportSize.openLightboxButtonText !== undefined ? viewportSize.openLightboxButtonText : defaultResponsive[defaultViewportSizeIndex].openLightboxButtonText,
                    ];   
                    return true;             
                }
                return false;
            });
    
            setNumVisible(currentNumVisible);
            setIsMobile(viewportWidth <= 1024);
    
            if (thumbnailsRef.current) {
                thumbnailsRef.current.scrollLeft = currentScrollPosition; //rever
                centerThumbnail(lightboxIndex);
            }
        };
    
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [responsive, lightboxIndex]);  

    /*Handles Element Z-Index when Lightbox is Open*/
    useEffect(() => {
        const updateZIndexes = () => {
            zIndexElements.forEach((selector) => {
                const elements = document.querySelectorAll(selector);
                const lightbox = document.querySelector('.lightbox');
                elements.forEach((element) => {
                    if (element instanceof HTMLElement) {
                        if (lightboxActive) {
                            lightbox.style.zIndex = '99';
                            element.style.zIndex = '0';
                        } else {
                            setTimeout(() => {
                                element.style.zIndex = '99';
                                lightbox.style.zIndex = '-1';
                            }, 300);
                        }
                    }
                });
            });
        };

        if(lightbox && imageGallery){
            updateZIndexes();
            document.body.style.overflow = lightboxActive ? 'hidden' : 'auto';
        }
    
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [lightbox, imageGallery, lightboxActive, zIndexElements]);

    /*Custom Color Change*/
    const setCssVariable = (name, value) => {
        document.documentElement.style.setProperty(`--${name}`, value);
    };

    useEffect(() => {
        const defaultColors = {
            lightboxBackground: 'rgba(0, 0, 0, 0.9)',
            captionBackground: 'rgba(0, 0, 0, 0.5)',
            captionColor: '#fafafa',
            navColor: '#fafafa',
            navBackground: 'rgba(0, 0, 0, 0.5)',
            navColorHover: '#fafafa',
            navBackgroundHover: 'rgba(0, 0, 0, 1)',
            lightboxNavColor: '#fafafa',
            lightboxNavHoverColor: '#ccc',
            dotColor: '#ccc',
            dotActiveColor: '#333',
            inactiveThumbnailsBorder: '2px solid transparent',
            inactiveThumbnailsFilter: 'grayscale(1)',
            activeThumbnailBorder: '2px solid #fafafa',
            activeThumbnailFilter: 'grayscale(0)',
            openLightboxButtonColor: '#fafafa',
            openLightboxButtonBackground: 'rgba(0, 0, 0, 1)',
        };

        if (colors) {
            Object.keys(defaultColors).forEach(key => {
                setCssVariable(key, colors?.[key] || defaultColors[key]);
            });
        }
    }, [colors]);

    /*Gallery Pagination Handler*/
    const paginate = (newDirection) => {
        if (!buttonDisabled) {
            const newPage = wrap(0, items.length, page + newDirection);
            setPage([newPage, newDirection]);
            setButtonDisabled(true);
            setTimeout(() => setButtonDisabled(false), 400);
        }
    };

    /*Gallery Item Click Handler*/
    const handleItemClick = (index) => {
        if (lightbox) {
            setLightboxActive(true);
            setLightboxIndex(index);

            if (thumbnailRefs.current[index]) {
                thumbnailRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }
    };

    /*Dots Click Handler*/
    const goToSlide = (index) => {
        const newPage = index * itemsPerDot;
        setPage([newPage, newPage > page ? 1 : -1]);
    };

    /*Lightbox Pagination Handler*/
    const lightboxPaginate = (newDirection) => {
        if (!buttonDisabled) {
            const newIndex = wrap(0, items.length, lightboxIndex + newDirection);
            setPage([newIndex, newDirection]);
            setLightboxIndex(newIndex);

            if (thumbnailRefs.current[newIndex]) {
                thumbnailRefs.current[newIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
            setButtonDisabled(true);
            setTimeout(() => setButtonDisabled(false), 400);
        }
    };

    /*Center Active Lightbox Thumbnail Handler*/
    const centerThumbnail = (index) => {
        if (thumbnailRefs.current[index]) {
            thumbnailRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    };

    /*Lightbox Close Handler*/
    const handleCloseLightbox = () => {
        setLightboxActive(false);
    };

    /*Lightbox Thumbnail Click Handler*/
    const handleThumbnailClick = (index) => {
        const direction = index > lightboxIndex ? 1 : -1;
        setPage([index, direction]);
        setLightboxIndex(index);
    
        if (thumbnailRefs.current[index]) {
            thumbnailRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    };

    /*Current Gallery Items Handler*/
    const currentItems = [];
    for (let i = 0; i < numVisible; i++) {
        const itemIndex = wrap(0, items.length, page + i);
        currentItems.push(items[itemIndex]);
    }

    return (
        <>
            {!onlyLightbox && layout === 'carousel' ? (
                <>
                    <div
                        className={`gallery-carousel ${lightboxActive ? 'lightbox-active' : ''}`}
                        style={{
                            height: galleryHeight,
                        }}
                    >
                        <AnimatePresence initial={false} custom={direction}>
                            {currentItems.map((item, index) => (
                                <motion.div
                                    className="gallery-carousel__item"
                                    key={`${page}-${index}`}
                                    custom={direction}
                                    variants={slideVariants}
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    transition={{
                                        x: { type: 'tween', stiffness: 300, damping: 30 },
                                        opacity: { duration: 0.5 }
                                    }}
                                    drag={isMobile ? "x" : false}
                                    dragConstraints={{ left: 0, right: 0 }}
                                    dragElastic={0}
                                    onDragEnd={(e, { offset, velocity }) => {
                                        const swipe = swipePower(offset.x, velocity.x);
                                        if (swipe < -swipeConfidenceThreshold) {
                                            paginate(1);
                                        } else if (swipe > swipeConfidenceThreshold) {
                                            paginate(-1);
                                        }
                                    }}
                                    onClick={() => handleItemClick(wrap(0, items.length, page + index))}
                                    style={{
                                        left: `${index * (100 / numVisible)}%`,
                                        width: `calc(100% / ${numVisible})`,
                                        cursor: lightbox && imageGallery ? 'pointer' : 'default'
                                    }}
                                >
                                    {imageGallery ? (
                                        <>
                                            <img className="gallery__image" src={item[0]} alt={`${item[1] ? item[1] : wrap(0, items.length, page - 1 + index)}`} />
                                            <div className="gallery__overlay">{item[1]}</div>
                                        </>
                                    ) : (
                                        item
                                    )}
                                </motion.div>
                            ))}
                        </AnimatePresence>
                        <div
                            className='gallery-carousel__next'
                            onClick={() => paginate(1)}
                            disabled={buttonDisabled}
                            style={{
                                display: nav ? 'flex' : 'none',
                            }}
                        >
                            {navText[1]}
                        </div>
                        <div
                            className='gallery-carousel__prev'
                            onClick={() => paginate(-1)}
                            disabled={buttonDisabled}
                            style={{
                                display: nav ? 'flex' : 'none',
                            }}
                        >
                            {navText[0]}
                        </div>
                    </div>
                    <div
                        className="gallery-carousel__dots"
                        style={{
                            display: dots ? 'block' : 'none',
                        }}
                    >
                        <div className="gallery-carousel__dots" style={{ display: dots ? 'block' : 'none' }}>
                            {Array.from({ length: Math.ceil(items.length / itemsPerDot) }).map((_, dotIndex) => (
                                <button
                                    key={dotIndex}
                                    className={`dot ${Math.floor(page / itemsPerDot) === dotIndex ? 'active' : ''}`}
                                    onClick={() => goToSlide(dotIndex)}
                                />
                            ))}
                        </div>
                    </div>
                </>
            ) : !onlyLightbox && layout === 'grid' ? (
                <div
                    className="gallery-grid"
                    style={{
                        gridTemplateColumns: `repeat(${numVisible}, 1fr)`,
                        gridAutoRows: galleryHeight,
                    }}
                >
                    {items.map((item, index) => (
                        <div
                            className="gallery-grid__item"
                            key={index}
                            onClick={() => handleItemClick(index)}
                            style={{
                                cursor: lightbox && imageGallery ? 'pointer' : 'default',
                                gridColumn: gridLayout.length > 0 ? `span ${gridLayout[index % gridLayout.length][0]}` : 'span 1',
                                gridRow: gridLayout.length > 0 ? `span ${gridLayout[index % gridLayout.length][1]}` : 'span 1',
                            }}
                        >
                            {imageGallery ? (
                                <>
                                    <img className="gallery__image" src={item[0]} alt={`${item[1] ? item[1] : index}`} />
                                    <div className="gallery__overlay">{item[1]}</div>
                                </>
                            ) : (
                                item
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <button className='lightbox__open' onClick={() => handleItemClick(lightboxIndex)}>{openLightboxButtonText}</button>
            )}
            {lightbox && imageGallery ? (
                <div
                    className="lightbox"
                    style={{
                        opacity: lightboxActive ? 1 : 0,
                        pointerEvents: lightboxActive ? 'auto' : 'none',
                    }}
                >
                    <div className="lightbox__content">
                        <button
                            className="lightbox__close"
                            onClick={handleCloseLightbox}
                        >
                            {lightboxNavText[2]}
                        </button>
                        <div
                            className="lightbox__image__container"
                            style={{
                                height: lightboxThumbnails ? '85%' : '100%',
                            }}
                        >
                            <AnimatePresence initial={false} custom={direction}>
                                <motion.div
                                    key={lightboxIndex}
                                    custom={direction}
                                    variants={slideVariants}
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    transition={{
                                        x: { type: 'tween', stiffness: 300, damping: 30 },
                                        opacity: { duration: 0.5 }
                                    }}
                                    style={{
                                        padding: isMobile ? '1rem 0' : '1rem',
                                    }}
                                    className="lightbox__image__container__animated"
                                    drag={isMobile ? 'x' : false}
                                    dragConstraints={{ left: 0, right: 0 }}
                                    dragElastic={0}
                                    onDragEnd={(event, info) => {
                                        const swipe = info.offset.x;
                                        const threshold = 100;
                                        if (swipe > threshold) {
                                            lightboxPaginate(-1);
                                        } else if (swipe < -threshold) {
                                            lightboxPaginate(1);
                                        }
                                    }}
                                >
                                    <img
                                        src={items[lightboxIndex][0]}
                                        alt={`${items[lightboxIndex][1] ? items[lightboxIndex][1] : lightboxIndex}`}
                                        className="lightbox__image"
                                    />
                                    <div className="gallery__caption">
                                        {items[lightboxIndex][1] ? items[lightboxIndex][1] : lightboxIndex}
                                    </div>
                                </motion.div>
                            </AnimatePresence>
                        </div>
                        {lightboxThumbnails ? (
                            <div className="lightbox__thumbnails">
                                <motion.div className="lightbox__thumbnails__inner" ref={thumbnailsRef} >
                                    {items.map((thumbnail, index) => (
                                        <img
                                            key={index}
                                            src={thumbnail[0]}
                                            ref={(el) => (thumbnailRefs.current[index] = el)}
                                            onClick={() => handleThumbnailClick(index)}
                                            alt={`${thumbnail[1] ? thumbnail[1] : index}`}
                                            style={{
                                                margin: index === 0 ? '0 6px 0 0' : index === items.length - 1 ? '0 0 0 6px' : '0 6px',
                                            }}
                                            className={lightboxIndex === index ? 'selected-thumbnail' : ''}
                                        />
                                    ))}
                                </motion.div>
                            </div>
                        ) : (
                            ''
                        )}
                        {lightboxNav ? (
                            <>
                                <button
                                    style={{
                                        top: lightboxThumbnails ? 'calc((100* * 0.85) * 0.5)' : '50%',
                                    }}
                                    className="lightbox__prev"
                                    onClick={() => lightboxPaginate(-1)}
                                >
                                    {lightboxNavText[0]}
                                </button>
                                <button
                                    style={{
                                        top: lightboxThumbnails ? 'calc((100% * 0.85) * 0.5)' : '50%',
                                    }}
                                    className="lightbox__next"
                                    onClick={() => lightboxPaginate(1)}
                                >
                                    {lightboxNavText[1]}
                                </button>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Gallery;