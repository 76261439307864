import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import config from '../config';

const basePath = config.baseURL;
const removeBasePath = (url) => {
    return url.replace(basePath, '');
};

const FrontendNavbar = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingLogo, setLoadingLogo] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const getSiteInfo = async () => {
      try {
          const response = await fetch('https://reactapi.celeuma.pt/wp-json/api/v1/siteinfo');
          const infos = await response.json();
          setInfo(infos);
          setLoadingLogo(false);
      } catch (error) {
          console.error('Erro a obter info!', error);
          setLoadingLogo(false);
      }
    };

    const getMenuItems = async () => {
      try {
        const response = await fetch('https://reactapi.celeuma.pt/wp-json/api/v1/menus?menu=top-menu');
        const fetchedMenuItems = await response.json();

        const updatedMenuItems = fetchedMenuItems.map(item => ({
          ...item,
          url: removeBasePath(item.url)
        }));

        setMenuItems(updatedMenuItems); 
        setLoading(false);
      } catch (error) {
        console.error('Erro a obter menu!', error);
        setLoading(false); 
      }
    };

    getSiteInfo();
    getMenuItems(); 
  }, []); 

  return (
    <nav className="position-fixed navfe w-100">
      <div className="container-fluid px-4 d-flex align-items-center justify-content-between py-2">
        {loadingLogo ? (
          <div className='logo-home shimmer'>&nbsp;</div> 
        ) : (
          <Link to="/">
            <img className="logo-home" src={info.logo} alt="Logo" />
          </Link>
        )}
        {loading ? (
          <p className='w-100 shimmer mb-0'>&nbsp;</p> 
        ) : (
          <ul>
            {menuItems.length > 0 ? (
              menuItems.map(item => (
                <li key={item.ID}>
                  <Link to={item.url} className={location.pathname === item.url ? 'current' : ''}>
                    {item.title}
                  </Link>
                </li>
              ))
            ) : (
              <p>Não existem itens.</p>
            )}
          </ul>
        )}
      </div>
    </nav>
  );
};

export default FrontendNavbar;
