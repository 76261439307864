import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/style.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FrontendLayout from './views/layout';
import Home from './views/home';
import Page from './views/page';
import Artigo from './views/artigo';
import Artigo2 from './views/artigo2';
import NotFound from './views/404';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<FrontendLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/:slug" element={<Page />} />
          <Route path="/artigos/:slug" element={<Artigo />} />
          <Route path="/artigos2/:slug" element={<Artigo2 />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;