import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from 'popmotion';

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

const Banner = ({ images }) => {
    const [[page, direction], setPage] = useState([0, 0]);

    const imageIndex = wrap(0, images.length, page);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection]);
    };

    return (
        <div className="banner__item">
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    className="banner__item__inner"
                    key={page}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: "spring", stiffness: 300, damping: 30 },
                        opacity: { duration: 0.2 }
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1);
                        }
                    }}
                >
                    {images[imageIndex].video && images[imageIndex].video.url ? (
                        <video
                            className="banner__video"
                            autoPlay
                            loop
                            muted
                            playsInline
                        >
                            <source src={images[imageIndex].video.url} type={images[imageIndex].video.mime} />
                        </video>
                    ) : (
                        <img
                            className="banner__image"
                            src={images[imageIndex].thumbnail_url}
                            alt={images[imageIndex].post_title}
                        />
                    )}
                    <div className="banner__item__content d-flex flex-column align-items-center justify-content-center">
                        <p className='banner__item__title mb-0'>{images[imageIndex].post_title}</p>
                        <div dangerouslySetInnerHTML={{ __html: images[imageIndex].post_content }} />
                        {images[imageIndex].hiperligacao ? (
                            <Link
                                className="btnsfe btnsfe--lightorange d-inline-block mt-4"
                                to={images[imageIndex].hiperligacao}
                                target={images[imageIndex].target === 1 ? '_blank' : '_self'}
                            >
                                {images[imageIndex].linktext}
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                </motion.div>
            </AnimatePresence>
            <div className="next" onClick={() => paginate(1)}>
                {'‣'}
            </div>
            <div className="prev" onClick={() => paginate(-1)}>
                {'‣'}
            </div>
        </div>
    );
};

export default Banner;