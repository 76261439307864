import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Banner from './banner';
import config from '../config';

const basePath = config.baseURL;
const removeBasePath = (url) => {
    return url.replace(basePath, '');
};

export default function Home() {
    const [loading, setLoading] = useState(true);
    const [postLoadingStates, setPostLoadingStates] = useState(true);
    const [bannerLoadingStates, setBannerLoadingStates] = useState(true);
    const [posts, setPost] = useState([]);
    const [banner, setBanner] = useState([]);
    const [info, setInfo] = useState([]);

    useEffect(() => {
        const getSiteInfo = async () => {
            try {
                const response = await fetch('https://reactapi.celeuma.pt/wp-json/api/v1/siteinfo');
                const infos = await response.json();
                setInfo(infos);
                setLoading(false);
            } catch (error) {
                console.error('Erro a obter info!', error);
                setLoading(false);
            }
        };
        
        const getPosts = async () => {
            try {
                const response = await fetch('https://reactapi.celeuma.pt/wp-json/api/v1/artigos');
                const posts = await response.json();

                const updatedPostData = posts.map(item => ({
                    ...item,
                    url: '/artigos' + removeBasePath(item.url)
                }));

                setPost(updatedPostData);
                setPostLoadingStates(false);
            } catch (error) {
                console.error('Erro a obter posts!', error);
                setPostLoadingStates(false);
            }
        };

        const getBanner = async () => {
            try {
                const response = await fetch('https://reactapi.celeuma.pt/wp-json/api/v1/banners');
                const banners = await response.json();
                setBanner(banners);
                setBannerLoadingStates(false);
            } catch (error) {
                console.error('Erro a obter banner!', error);
                setBannerLoadingStates(false);
            }
        };

        getSiteInfo();
        getPosts();
        getBanner();
    }, []);

    return (
        <>
            {loading ? (
                <Helmet>
                    <title>A carregar</title>
                    <meta name="description" content="A carregar" />
                    <link rel="icon" href="favicon.png" />
                    <link rel="apple-touch-icon" href="favicon.png" />
                </Helmet>
            ) : (
                <Helmet>
                    <title>{info.title}</title>
                    <meta name="description" content={info.description} />
                    <link rel="icon" href={info.favicon} />
                    <link rel="apple-touch-icon" href={info.favicon} />
                </Helmet>
            )}
            <section className="banner px-0 container-fluid">
                {bannerLoadingStates ? (
                    <div className="banner__item shimmer"></div>
                ) : (
                    <Banner images={banner} />
                )}
            </section>
            <section className="container-fluid bg position-relative">
                {postLoadingStates ? (
                    <div className="row">
                        <div className='col-md-4 bolos shimmer'><div className="bolos__inner">&nbsp;</div></div>
                        <div className='col-md-4 bolos shimmer'><div className="bolos__inner">&nbsp;</div></div>
                        <div className='col-md-4 bolos shimmer'><div className="bolos__inner">&nbsp;</div></div>
                        <div className='col-md-4 bolos shimmer'><div className="bolos__inner">&nbsp;</div></div>
                        <div className='col-md-4 bolos shimmer'><div className="bolos__inner">&nbsp;</div></div>
                        <div className='col-md-4 bolos shimmer'><div className="bolos__inner">&nbsp;</div></div>
                    </div>
                ) : (
                    <div className="row">
                        {posts.length > 0 ? (
                            posts.map((post, index) => {
                                const isRight = Math.floor(index / 3) % 2 === 0;
                                const className = `col-md-4 bolos ${isRight ? 'bolos--right' : 'bolos--left'}`;
                                return (
                                    <Link to={post.url} className={className} key={post.ID}>
                                        <div className="bolos__inner text-center d-flex flex-column justify-content-center">
                                            <h3>{post.post_title}</h3>
                                            <div dangerouslySetInnerHTML={{ __html: post.post_content }} />
                                        </div>
                                        <div className="bolos__foto">
                                            {post.thumbnail_url ? (
                                                <img src={post.thumbnail_url} alt={post.post_title} />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </Link>
                                );
                            })
                        ) : (
                            <p>Não existem posts.</p>
                        )}
                    </div>
                )}
            </section>
        </>
    );
};
