import React from 'react';
import { Outlet } from 'react-router-dom';
import FrontendNavbar from './navbar';

const FrontendLayout = () => {

  return (
    <div className='position-relative'>
        <FrontendNavbar />
        <main>
            <Outlet />
        </main>
    </div>
  );
};

export default FrontendLayout;
