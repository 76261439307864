import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import NotFound from './404';
import About from './about';
import Artigos from './artigos';
import Artigos2 from './artigos2';
import Contactos from './contactos';

const Pages = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [postLoadingState, setPostLoadingState] = useState(true);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const getPosts = async () => {
            try {
                const response = await fetch(`https://reactapi.celeuma.pt/wp-json/api/v1/item?slug=${slug}&type=page`);
                if (!response.ok) {
                    setNotFound(true);
                } else {
                    const posts = await response.json();
                    setPost(posts);
                    setPostLoadingState(false);
                    setNotFound(false);
                }
            } catch (error) {
                console.error('Erro a obter o artigo!', error);
                setPostLoadingState(false);
                setNotFound(true);
            }
        };

        setPostLoadingState(true);
        setNotFound(false);

        getPosts();
    }, [slug]);

    return (
        notFound ? (
            <NotFound />
        ) : (
            postLoadingState ? (
                <>
                    <Helmet>
                        <title>A carregar</title>
                        <meta name="description" content="A carregar" />
                        <link rel="icon" href="favicon.png" />
                        <link rel="apple-touch-icon" href="favicon.png" />
                    </Helmet>
                    <p>A carregar...</p>
                </>
            ) : (
                <>
                    <Helmet>
                        {post.meta_title ? (
                            <title>{post.meta_title}</title>
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_metadesc'] ? (
                            <meta name="description" content={post.meta['_yoast_wpseo_metadesc'][0]} />
                        ) : (
                            ''
                        )}
                        {post.favicon ? (
                            <>
                                <link rel="icon" href={post.favicon} />
                                <link rel="apple-touch-icon" href={post.favicon} />
                            </>
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_opengraph-title'] ? (
                            <meta property="og:title" content={post.meta['_yoast_wpseo_opengraph-title'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_opengraph-description'] ? (
                            <meta property="og:description" content={post.meta['_yoast_wpseo_opengraph-description'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_opengraph-image'] ? (
                            <meta property="og:image" content={post.meta['_yoast_wpseo_opengraph-image'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_twitter-title'] ? (
                            <meta name="twitter:title" content={post.meta['_yoast_wpseo_twitter-title'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_twitter-description'] ? (
                            <meta name="twitter:description" content={post.meta['_yoast_wpseo_twitter-description'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_twitter-image'] ? (
                            <meta name="twitter:image" content={post.meta['_yoast_wpseo_twitter-image'][0]} />
                        ) : (
                            ''
                        )}
                        {post.meta['_yoast_wpseo_focuskw'] ? (
                            <meta name="keywords" content={post.meta['_yoast_wpseo_focuskw'][0]} />
                        ) : (
                            ''
                        )}
                        <meta name="twitter:card" content="summary_large_image" />
                    </Helmet>

                    {post.template === 'Sobre Nós' ? (
                        <About post={ post } />
                    ) : post.template === 'Artigos' ? (
                        <Artigos post={ post } />
                    ) : post.template === 'Artigos2' ? (
                        <Artigos2 post={ post } />
                    ) : post.template === 'Contactos' ? (
                        <Contactos post={ post } />
                    ) : (
                        <section className="container-fluid bg position-relative">
                            <p>{post.post_title}</p>
                            <div dangerouslySetInnerHTML={{ __html: post.post_content }} />
                        </section>
                    )}
                </>
            )
        )
    );
};

export default Pages;